div.meta.wrapper {
    font-size: 15px; 
    line-height: 24px;
    padding: 200px 20px;
    @media screen and (max-width: $small) {
        padding: 130px 20px;
    }
    h1, h2, h3 {
        font-size: 47px;
        font-weight: normal;
        text-transform: uppercase;
    }
    h2, h3 {
        font-size: 24px;
        text-transform: none;
    }
    ul,
    p {
        margin: 0 0 2.5em;
    }

    a {
        color: $primary;
        text-decoration: none;
        transition: color 500ms ease;
        &:hover {
            color: $color;
        }
    }
}