.button {
  display: inline-block;
  line-height: 74px;
  border-radius: 37px;
  background: $color;
  color: white;
  text-transform: uppercase;
  font-size: 16px;
  padding: 0 40px;
  font-family: $font;
  font-weight: 300;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.1em;
  box-shadow: 0;
  transition: box-shadow 500ms ease, color 500ms ease, background-color 500ms ease;
  &--small {
    line-height: 60px;
  }
  @media screen and (max-width: $large) {
    line-height: 55px;
    font-size: 12px;
  }
  &:hover {
    box-shadow: 0 0 50px rgba(#444, 0.5);
    color: $color;
    background: white;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 120px;
  .button {
    margin: 0 0 20px;
    min-width: 200px;
    @media screen and (max-width: $large) {
      min-width: 150px;
    }
  }
}