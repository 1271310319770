header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 34px 42px;
  z-index: 10;
  @media screen and (max-width: $medium) {
    padding: 20px;
  }
  a {
    svg {
      width: 325px;
      @media screen and (max-width: $small) {
        width: 180px;
      }
    }
  }
  &.meta {
    a {
      svg {
        * {
          fill: $color;
        }
      }
    }
  }
}