#stage {
  height: 100vh;
  min-height: 870px;
  overflow: hidden;
  background: $primary;
  position: relative;
  margin-bottom: 130px;
  @media screen and (max-width: $small) {
    height: auto;
    overflow: visible;
  }
  .virus {
    position: absolute;
    top: 5%;
    right: 19%;
    width: 47.62%;
    @media screen and (max-width: $small) {
      width: 100%;
      right: - 50%;
    }
  }

  .badge {
    position: absolute;
    bottom: 12%;
    right: 35%;
    @media screen and (max-width: $small) {
      max-width: 50%;
      left: 20px;
      right: auto;
      bottom: 28%;
    }
  }

  .guy {
    position: absolute;
    bottom: -20%;
    right: -5%;
    height: 115%;
    @media screen and (max-width: $medium) {
      right: -20%;
    }
    @media screen and (max-width: $small) {
      width: 100%;
      height: auto;
      position: static;
      display: block;
    }
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    span.claim {
      font-size: 78px;
      line-height: 86px;
      transform: rotate(-15deg);
      position: absolute;
      top: 200px;
      left: -150px;
      z-index: 0;
      @media screen and (max-width: $large) {
        top: 23vh;
        left: 0;
        font-size: 60px;
        transform: rotate(-5deg);
      }

      @media screen and (max-width: $small) {
        font-size: 30px;
        top: 80px;
        left: 20px;
      }
    }

    h1 {
      position: relative;
      z-index: 1;
      font-weight: normal;
      font-size: 42px;
      line-height: 46px;
      margin: 100px 0 40px;
      word-wrap: break-word;
      color: white;
      @media screen and (max-width: $large) {
        font-size: 42px;
        line-height: 46px;
        margin-top: 130px;
      }
      @media screen and (max-width: $small) {
        font-size: 35px;
        line-height: 40px;
        margin-top: 180px;
      }
    }
  }
}
