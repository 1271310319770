

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-Bold.woff2') format('woff2'), url('/fonts/HKGrotesk-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-BoldItalic.woff2') format('woff2'), url('/fonts/HKGrotesk-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-Italic.woff2') format('woff2'), url('/fonts/HKGrotesk-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-Light.woff2') format('woff2'), url('/fonts/HKGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-LightItalic.woff2') format('woff2'), url('/fonts/HKGrotesk-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-Medium.woff2') format('woff2'), url('/fonts/HKGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-MediumItalic.woff2') format('woff2'), url('/fonts/HKGrotesk-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-Regular.woff2') format('woff2'), url('/fonts/HKGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-SemiBold.woff2') format('woff2'), url('/fonts/HKGrotesk-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'HKGrotesk';
  src: url('/fonts/HKGrotesk-SemiBoldItalic.woff2') format('woff2'), url('/fonts/HKGrotesk-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}