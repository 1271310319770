.layer {
  position: fixed;
  z-index: 50;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translate(0, -100%);
  transition: transform 500ms ease, opacity 500ms ease;
  &.active {
    opacity: 1;
    transform: translate(0, 0);
  }
  .content {
    background: white;
    padding: 50px 45px;
    box-sizing: border-box;
    max-width: 595px;
    max-height: 95vh;
    overflow-y: auto;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    position: relative;
    @media screen and (max-width: $small) {
      padding: 60px 30px;
      max-height: 80vh;
    }
    a.close {
      width: 50px;
      height: 50px;
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 6px;
        background: $color;
        transition: background-color 500ms ease;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &:hover {
        &:before,
        &:after {
          background: $primary;
        }
      }
    }
    h2 {
      font-size: 43px;
      line-height: 47px;
      font-weight: normal;
      margin: 0 0 1em;
      strong {
        color: $primary;
      }
    }
  }
}