#footer {
    background: $primary;
    .row {
        display: flex;
        box-sizing: border-box;
        padding: 80px 0;
        a {
            text-decoration: underline;
        }
        .column {
            flex: 2;
            font-size: 19px;
            line-height: 32px;
            position: relative;
            @media screen and (max-width: $medium) {
                font-size: 15px;
                line-height: 24px;
              }
            box-sizing: border-box;
            padding-right: 50px;
            &:last-child {
                flex: 2;
                padding-right: 0;
            }
            h3 {
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 0.135em;
                text-transform: uppercase;
                font-weight: normal;
                margin: 0 0 1.5em;
                height: 48px;
            }
            h6 {
                margin: 0;
                font-weight: 300;
            }
            .text {
                margin: 0 0 2em;
            }
            span {
                font-size: 24px;
            }
            strong {
                font-size: 11px;
            }
            a {
                color: black;
                text-decoration: underline;
                transition: color 300ms ease;
                &:hover {
                    color: lighten(black, 30%);
                }
            }
            .meta {
                display: block;
                margin: 35px 0;
                position: absolute;
                bottom: 0;
                @media screen and (max-width: $small) {
                    position: static;
                }
            }
            a.imprint {
                text-transform: uppercase;
                display: inline-block;
                margin-right: 1em;
                font-size: 11px;
                text-decoration: none;
                color: black;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @media screen and (max-width: 768px) {
            display: block;
            .column {
                margin-bottom: 35px;
            }
        }
    }
}