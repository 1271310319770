form {
  text-align: left;
  &.hide {
    display: none;
  }
  div {
    display: flex;
    flex-wrap: wrap;
    .field {
      width: 50%;
      padding: 0 10px;
      box-sizing: border-box;
      margin: 0 0 20px;
      &--full {
        width: 100%;
      }
      @media screen and (max-width: $small) {
        width: 100%;
      }
      textarea,
      input {
        outline: none;
        box-sizing: border-box;
        width: 100%;
        padding: 10px 10px;
        border-radius: none;
        border: 1px solid black;
        font-size: 15px;
        font-family: $font;
        border-radius: 6px;
      }

      &__checkbox,
      &__radio {
        display: flex;
        width: auto;
        @media screen and (max-width: $small) {
          width: auto;
        }
        input {
          position: absolute;
          left: -999em;
          opacity: 0;
        }
        label {
          padding-left: 30px;
          padding-right: 20px;
          position: relative;
          cursor: pointer;
          &:after {
            width: 20px;
            height: 20px;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            border-radius: 50%;
            left: 0;
            border: 1px solid black;
            box-sizing: border-box;
          }
          &:before {
            width: 10px;
            height: 10px;
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            border-radius: 50%;
            left: 5px;
            background: $primary;
            border: 1px solid black;
            box-sizing: border-box;
            opacity: 0;
            transform: scale(0.5);
            transition: opacity 500ms ease, transform 500ms ease;
          }
        }
        input:checked {
          & + label {
            &:before {
              opacity: 1;
              transform: scale(1);
            }
          }
        }
      }

      &__checkbox {
        width: auto;
        label {
          a { color: $color }
          &:before,
          &:after {
            border-radius: 0;
          }
        }
      }

      .button {
        margin: 40px auto 0;
      }

      
    }

  }

  .error {
    textarea,
    input {
      border: 1px solid red !important;
    }
    label {
      color: red;
    }
  }
}

.successMessage {
  display: none;
  &.active {
    display: block;
  }
}