// COLORS
$primary: #66ff66;
$color: #000;
$lightgrey: #e5e5e5;
$academy: #001986;
$font: 'HKGrotesk', 'Arial', sans-serif;

// SIZES
$hd: 1920px;
$xlarge: 1500px;
$large: 1380px;
$medium: 1040px;
$small: 768px;

$wrapper: 1180px;