@import "configs/general";
@import "configs/fonts";
@import "configs/normalize";
@import "partials/header";
@import "partials/footer";
@import "partials/stage";
@import "partials/meta";
@import "partials/button";
@import "partials/intro";
@import "partials/layer";
@import "partials/form";

html,
body {
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font;
    background: white;
    color: black;
    overflow: hidden;
    max-width: 100vw;
}

main {
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
}

.wrapper {
    max-width: 1180px;
    margin: 0 auto;
    position: relative;
    z-index: 20;
    padding: 0 20px;
    @media screen and (max-width: $medium) {
      overflow-x: hidden;
    }
    @media screen and (max-width: $small) {
      p {
        width: 95%;
      }
    }
  }

  h5 {
    color: $primary;
    letter-spacing: 0.135em;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 10px;
    &.white {
      color: white;
    }
    .academy & {
      color: $academy;
    }
    @media screen and (max-width: $medium) { 
      font-size: 14px;
    }
    @media screen and (max-width: $small) {
      font-size: 11px;
      margin: 0 0 10px;
    }
  }
  
  .deco-virus-footer,
  .deco-virus {
      position: absolute;
      width: 150px;
      transform: translate(-50%, -200px);
      left: 50%;
  }
  .deco-virus-footer {
    left: 33.33%;
    width: 100px;
    transform: translate(-50%, -50px);
  }