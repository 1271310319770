.intro {
  display: flex;
  margin-bottom: 165px;
  @media screen and (max-width: $small) {
    display: block;
    margin-bottom: 100px;
  }
  &Headline {
    width: 37%;
    flex: 1;
    @media screen and (max-width: $small) {
      width: auto;
      margin-bottom: 1em;
    }
    h5 {
      margin: 0 0 0.5em;
    }
    h3 {
      font-size: 38px;
      line-height: 47px;
      font-weight: 300;
      margin: 0 0 40px;
      @media screen and (max-width: $medium) { 
        font-size: 30px;
        line-height: 38px;
      }
      @media screen and (max-width: $small) { 
        font-size: 25px;
        line-height: 30px;
      }
    }
    div.image {
      width: 90%;
      position: relative;
      @media screen and (max-width: $small) {
        display: none;
      }
      &:after {
        content: '';
        display: block;
        padding-top: 90%;
      }
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        
      }
    }
  }
  &Content {
    width: 63%;
    box-sizing: border-box;
    padding-left: 50px;
    font-size: 18px;
    line-height: 30px;
    @media screen and (max-width: $small) {
      width: auto;
      padding-left: 0;
    }
    @media screen and (max-width: $small) {
      font-size: 15px;
      line-height: 24px;
    }
    p {
      margin: 0 0 1.5em;
    }
    .introLinks {
      a {
        font-size: 15px;
        line-height: 1.2em;
        @media screen and (max-width: $medium) {
          font-size: 15px;
          line-height: 24px;
          margin-bottom: 30px;
        }
      }
    }

    div.image {
      width: 100%;
      img {
        width: 100%;
        display: none;
        @media screen and (max-width: $small) {
          display: block;
          width: auto;
          margin: 40px auto;
          max-width: 100%;
        }

      }
    }
  }
}